import { waitAuth } from "~/utils/middleware";

import { Sub } from "~/types/subscriptions";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(async () => {
    await waitAuth(Sub.Type.Partner);

    if (!__subscription().has(Sub.Type.Partner)) {
        log("❌ Has no Partner");
        return navigateTo("/");
    }

    log("✅ Has Partner");
});
